
:root {
	--primary:#002D62;
	--accent-1: #AD6F00;
	--accent-2: #613E00;
}

html {
	width: var(--window-width);
	overflow-x: hidden;
}

body {
	font-family: 'SF Pro Text', Verdana, Geneva, Tahoma, sans-serif;
	width: var(--window-width);
	overflow-x: hidden;
}

.page {
	margin-top: var(--navbar-height, 0);
	min-height: calc(var(--window-height) - var(--navbar-height));
	padding: 0;
}

.fill-container, .fill-parent {
	height: 100%;
	width: 100%;
}

.page-size {
	width: var(--window-width);
	height: calc(var(--window-height) - var(--navbar-height));
	margin: 0;
}

a {
	font-size: 21px;
}


.vh-align, .vhalign {
	display: flex;
	align-items: center;
	justify-content: center;
}

.v-align, .valign {
	display: flex;
	align-items: center;
}

.h-align, .halign {
	display: flex;
	justify-content: center;
}

.center-align {
	text-align: center;
}

.right-align {
	text-align: right;
}

.grey-text {
	color: gray;
}


.primary-text {
	color: var(--primary) !important;
}

.primary-bg {
	background-color: var(--primary) !important;
}

h1, h2, h3, h4, h5, h6 {
	color: var(--primary);
}

.MuiButton-root {
	z-index: 0;
}